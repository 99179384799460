/* File for global CSS */

html {
  scroll-behavior: smooth;
}

/* src/index.css or your main CSS file */

/* Modal Styles */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  height: auto;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/* Image Styles */
.modal img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.gallery img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
